const lang = {
  en: {
    signIn: "Sign In",
    signUp: "Sign Up",
    signOut: "Sign Out",
    newtovedicdestiny: "New to vedicdestiny?",
    password: "Password",
    email: "Email Address",
    name: "Full Name",
    alreadyAcc: "Already have an account?",
    welcome: "Welcome to",
    vedicdestiny: "VedicDestiny",
    finger: "A Finger Pointing to Reality",
    gptSearch: "GPT Search",
    loginDescription:"Chat without any hassle, Get Personalized Horoscope, and Start your Astral Journey with our Easy Chatbot Today",
    explore: "AstroBot",
    chat: "Chat with Astrologers",
    call: "Call to Astrologers",
    search: "Search for Astrologers",
    filter: "Filter",
    all: "All",
    topRated: "Top rated",
    offer: "Offer",
    vedic: "Vedic",
    numerology: "Numerology",
    tarot: "Tarot",
    faceReading: "Face Reading",
    vastu: "Vastu",
    lifeCoach: "Life Coach",
    psychologist: "Psychologist",
    palmistry: "Palmistry",
    orders: "Orders",
    exp: "Exp",
    years: "Years",
    min: "min",
    horoTitle: "Daily Horoscope",
    horoDesc:
      "Dive into the mystical world of astrology with our comprehensive horoscope section. Discover the secrets written in the stars and unlock insights into your personality, relationships, and future. Whether you're a seasoned astrology enthusiast or just curious about what the cosmos has in store for you, our horoscope section is your guide to understanding the celestial forces shaping your life.",
    Aries: "Aries",
    Taurus: "Taurus",
    Gemini: "Gemini",
    Cancer: "Cancer",
    Leo: "Leo",
    Virgo: "Virgo",
    Libra: "Libra",
    Scorpio: "Scorpio",
    Sagittarius: "Sagittarius",
    Capricorn: "Capricorn",
    Aquarius: "Aquarius",
    Pisces: "Pisces",
    ourAstrologers: "Our Astrologers",
    astroDesc: "13000+ Best Astrologers from India for Online Consultation",
    zodiacTitle: "Know Your Zodiac Sign",
    zodiacDesc:
      "A brief yet insightful exploration of the distinct traits and influences associated with each astrological symbol, providing valuable insights into personality and life paths based on birth dates. Delve into the mysteries of astrology and uncover the hidden depths of your zodiac identity.",
    zodiacBirth: "Date of Birth",
    zodiacTime: "Time Of Birth",
    zodiacPlace: "Place Of Birth",
    zodiacFind: "FIND",
    CHAT: "CHAT",
    CALL: "CALL",
    SIGNOUT: "SIGNOUT",
    FOLLOWING: "FOLLOWING",
    ASTROBOT: "ASTRO BOT",
    LOGIN: "LOGIN",
    KUNDLIGPT: "KUNDLI GPT",
    yourHoroscope: "Your Horoscope",
    todaysHoroof: " Todays Horoscope of",
    horobottomtitle1: "Today’s horoscope for zodiac signs",
    horobottomtitle2: "Unlock Your Cosmic Destiny with vedicdestiny Horoscopes",
    horobottomdesc1:
      " Welcome to the realm where stars align and destinies unfold. Dive into the celestial mysteries with vedicdestiny Horoscopes, where each prediction is a glimpse into the cosmic tapestry of your life. Our astrological insights, powered by cutting-edge GPT technology, guidance and illumination as you navigate the celestial currents. Step into the world of vedicdestiny Horoscopes and discover the wisdom written in the stars.",
    horobottomtitle3: "About vedicdestiny Horoscope Prediction:",
    horobottomdesc2:
      "Our vedicdestiny Horoscope Prediction brings the timeless wisdom of astrology into the digital age. Crafted by our advanced GPT models, our horoscopes offer personalized insights into your past, present, and future. While our predictions may not be grounded in traditional astrological practices, they serve as guiding lights in your journey through life's ever-changing landscape. Explore the cosmic whispers with vedicdestiny Horoscopes and unlock the secrets of the universe",
    horobottomtitle4: "How Today's Horoscope Can Guide You:",
    horopoint1:
      " Gain insights into potential opportunities and challenges that may arise throughout your day.",
    horopoint2:
      " Receive personalized guidance based on your zodiac sign, helping you make informed decisions.",
    horopoint3:
      " Find clarity and direction in various aspects of your life, including love, career, health, and personal growth.",
    horopoint4:
      "Use your horoscope as a celestial compass to navigate life's twists and turns with confidence.",
    horopoint5:
      "Embrace the wisdom of the stars and uncover the hidden meanings behind daily events.",
    horopoint6:
      "  Stay attuned to the cosmic energies surrounding you and make the most of each moment.",

    astroKundli: "Astro Kundli",
    astroBot: "Astro Bot",
    getAstroInsights: "Get Astro Insights",
    inputname: "Name",
    birthPlace: "Birth Place",
    birthDistrict: "Birth District",
    birthTime: "Birth Date & Time",
    gender: "Gender",
    male: "Male",
    female: "Female",
    language: "Language",
    notfollowing: "You are not following anyone!",
    expFollow: "Explore Astrologers",
    following: "Following",
    clearFollow: "Clear Follow",
    unfollow: "Unfollow",
    ABOUT: "ABOUT",
  },
  hindi: {
    signIn: "दाखिल करें",
    signUp: "साइन अप करें",
    signOut: "साइन आउट",
    newtovedicdestiny: "आस्ट्रोजीपीटी पर नए हैं?",
    password: "पासवर्ड",
    email: "मेल पता",
    name: "पूरा नाम",
    alreadyAcc: "क्या आपके पास पहले से एक खाता मौजूद है?",
    welcome: "आकाशीय डाइव इंटू",
    vedicdestiny: "आस्ट्रोजीपीटी",
    finger: "वास्तविकता की ओर इशारा करने वाली एक उंगली",
    gptSearch: "जीपीटी खोज",
    loginDescription:
      "ज्योतिषियों से जुड़ें, बिना किसी समस्या के चैट करें, व्यक्तिगत राशिफल प्राप्त करें, और आज ही हमारे सहज चैटबॉट के साथ अपनी खगोलीय यात्रा शुरू करें",
    explore: "अन्वेषण करें",
    chat: "ज्योतिषियों के साथ चैट करें",
    call: "ज्योतिषियों को कॉल करें",
    search: "ज्योतिषियों के लिए खोजें",
    filter: "फ़िल्टर",
    all: "सभी",
    topRated: "शीर्ष रेटेड",
    offer: "ऑफर",
    vedic: "वैदिक",
    numerology: "न्यूमेरोलॉजी",
    tarot: "तारो",
    faceReading: "फेस रीडिंग",
    vastu: "वास्तु",
    lifeCoach: "लाइफ कोच",
    psychologist: "मनोविज्ञानी",
    palmistry: "हस्तरेखा",
    orders: "आदेश",
    exp: "अनुभव",
    years: "वर्ष",
    min: "मिनट",
    horoTitle: "दैनिक राशिफल",
    horoDesc:
      "हमारे व्यापक राशिफल खंड के साथ ज्योतिष की रहस्यमय दुनिया में डुबकी लगाएं। तारों में लिखे गए रहस्यों को खोजें और अपनी व्यक्तित्व, रिश्तों और भविष्य के बारे में दृढ़ता से खोलें। चाहे आप एक प्रवीण ज्योतिष उत्साही हों या बस यह जानने की क्या कोस्मोस आपके लिए संभव है, हमारा राशिफल खंड आपके जीवन को आकार देने वाले आकाशीय बलों को समझने के लिए आपका मार्गदर्शक है।",
    Aries: "मेष",
    Taurus: "वृषभ",
    Gemini: "मिथुन",
    Cancer: "कर्क",
    Leo: "सिंह",
    Virgo: "कन्या",
    Libra: "तुला",
    Scorpio: "वृश्चिक",
    Sagittarius: "धनु",
    Capricorn: "मकर",
    Aquarius: "कुंभ",
    Pisces: "मीन",
    ourAstrologers: "हमारे ज्योतिषी",
    astroDesc: "ऑनलाइन परामर्श के लिए भारत से 13000 से अधिक श्रेष्ठ ज्योतिषी",
    zodiacTitle: "अपनी राशि जानें",
    zodiacDesc:
      "प्रत्येक ज्योतिषीय प्रतीक से जुड़े विशिष्ट गुणों और प्रभावों के एक संक्षिप्त और उत्तेजनापूर्ण अन्वेषण का वर्णन, जन्म तिथियों के आधार पर व्यक्तित्व और जीवन पथों के लिए मौलिक अद्यतन देता है। ज्योतिष के रहस्यों में खोजें और अपनी राशि की पहचान की छिपी हुई गहराईयों को खोलें।",
    zodiacBirth: "जन्म की तारीख",
    zodiacTime: "जन्म का समय",
    zodiacPlace: "जन्म स्थान",
    zodiacFind: "खोजें",
    CHAT: "चैट",
    CALL: "कॉल",
    SIGNOUT: "साइन आउट",
    FOLLOWING: "फॉलो कर रहे हैं",
    ASTROBOT: "एस्ट्रो बॉट",
    KUNDLIGPT: "कुंडली GPT",
    LOGIN: "लॉगिन",
    astroKundli: "ज्योतिष कुंडली",
    astroBot: "ज्योतिष बॉट",
    yourHoroscope: "आपकी कुंडली",
    todaysHoroof: "आज की कुंडली:",
    horobottomtitle1: "राशियों के लिए आज की कुंडली",
    horobottomtitle2:
      "आधुनिक vedicdestiny कुंडलियों द्वारा अपने ब्रह्मांडीय भविष्य को खोलें",
    horobottomdesc1:
      "सितारों की लाइनों में प्रवेश करें और vedicdestiny कुंडलियों के साथ आकाशीय रहस्यों में डूबें, जहां प्रत्येक पूर्वानुमान आपके जीवन के कॉस्मिक चादर का एक झलक है। हमारे ज्योतिषीय अनुभव, कटिबद्ध GPT प्रौद्योगिकी द्वारा प्रेरित, आपकी यात्रा के मार्गदर्शन और प्रकाशन के रूप में काम करते हैं।",
    horobottomtitle3: "एस्ट्रोजीपीटी कुंडली पूर्वानुमान के बारे में:",
    horobottomdesc2:
      "हमारे vedicdestiny कुंडली पूर्वानुमान आधुनिक GPT मॉडल्स द्वारा बनाए गए हैं। हमारे पूर्वानुमान आपके अतीत, वर्तमान, और भविष्य के व्यक्तिगत अनुभवों के लिए व्यक्तिगत प्रकाश प्रदान करते हैं। हालांकि हमारे पूर्वानुमान पारंपरिक ज्योतिषीय अभ्यासों पर नहीं आधारित हैं, वे जीवन के बदलते मानचित्र में आपके मार्गप्रदर्शन के प्रकाश हैं।",
    horobottomtitle4: "आज की कुंडली आपको कैसे मार्गदर्शन कर सकती है:",
    horopoint1:
      "आपके दिन में संभावित अवसरों और चुनौतियों के बारे में अंतर्दृष्टि प्राप्त करें।",
    horopoint2:
      "आपकी राशि के आधार पर व्यक्तिगत मार्गदर्शन प्राप्त करें, जो आपको सूचित निर्णय लेने में मदद करेगा।",
    horopoint3:
      "प्यार, करियर, स्वास्थ्य, और व्यक्तिगत विकास जैसे अनेक पहलुओं में स्पष्टता और मार्गदर्शन प्राप्त करें।",
    horopoint4:
      "अपनी कुंडली को आकाशीय कंपास के रूप में उपयोग करें और आत्मविश्वास से जीवन के मोड़ों को नेविगेट क",
    getAstroInsights: "ज्योतिष इंसाइट्स प्राप्त करें",
    inputname: "नाम",
    birthPlace: "जन्म स्थान",
    birthDistrict: "जन्म जिला",
    birthTime: "जन्म तारीख और समय",
    gender: "लिंग",
    male: "पुरुष",
    female: "महिला",
    language: "भाषा",
    notfollowing: "आप किसी का अनुसरण नहीं कर रहे हैं!",
    expFollow: "ज्योतिषियों का अन्वेषण करें",
    following: "अनुसरण",
    clearFollow: "स्पष्ट अनुसरण",
    unfollow: "अनुसरण छोड़ें",
    ABOUT: "बारे में",
  },
  marathi: {
    signIn: "साइन इन करा",
    signUp: "साइन अप करा",
    signOut: "साइन आउट करा",
    newtovedicdestiny: "आस्ट्रोजीपीटी वर नवीन?",
    password: "पासवर्ड",
    email: "ईमेल पत्ता",
    name: "पूर्ण नाव",
    alreadyAcc: "आधीपासूनच एक खाते आहे?",
    welcome: "खगोलीय डायव्ह इंटू",
    vedicdestiny: "आस्ट्रोजीपीटी",
    finger: "वास्तविकतेचा इशारा करणारा एक बोट",
    gptSearch: "जीपीटी शोध",
    loginDescription:
      "ज्योतिषियांशी संपर्क साधा, सारख्या चॅट साठी, वैयक्तिक राशिफल प्राप्त करा आणि आज तुमच्या सोप्या चॅटबॉटसह तुमच्या खगोलशास्त्रीय अभियानाला सुरुवात करा",
    explore: "शोध",
    chat: "ज्योतिष्यांशी चॅट करा",
    call: "ज्योतिष्यांना कॉल करा",
    search: "ज्योतिष्यांसाठी शोधा",
    filter: "फिल्टर",
    all: "सर्व",
    topRated: "टॉप दर्जा",
    offer: "ऑफर",
    vedic: "वैदिक",
    numerology: "संख्याशास्त्र",
    tarot: "तारो",
    faceReading: "चेहरा वाचन",
    vastu: "वास्तू",
    lifeCoach: "जीवन कोच",
    psychologist: "मानसशास्त्रज्ञ",
    palmistry: "हस्तरेखा",
    orders: "ऑर्डर्स",
    exp: "अनुभव",
    years: "वर्षे",
    min: "मिनिट्स",
    horoTitle: "दैनिक राशिफळ",
    horoDesc:
      "आपल्या पूर्ण राशिफळ विभागात ज्योतिषशास्त्राच्या रहस्यमय जगात डुगड्या घाला. तारांमध्ये लिहिलेले रहस्य शोधा आणि आपल्या व्यक्तिमत्त्व, नात्यांचे आणि भविष्याच्या बाबीत कोणत्याही इन्साइट्स उघडा. आपण ज्योतिषशास्त्राचे एक अनुभवी अनुयायी असला तरी किंवा फक्त आपल्याला असाच जाणून घ्यायचं असल्यास, आमच्या राशिफळ विभागात आपल्या जीवनाला स्वर्गीय शक्तियांची समजायची मार्गदर्शक आहे.",
    Aries: "मेष",
    Taurus: "वृषभ",
    Gemini: "मिथुन",
    Cancer: "कर्क",
    Leo: "सिंह",
    Virgo: "कन्या",
    Libra: "तुला",
    Scorpio: "वृश्चिक",
    Sagittarius: "धनु",
    Capricorn: "मकर",
    Aquarius: "कुंभ",
    Pisces: "मीन",
    ourAstrologers: "आमचे ज्योतिषी",
    astroDesc: "ऑनलाइन परामर्शासाठी भारतातील 13000+ उत्कृष्ट ज्योतिषी",
    zodiacTitle: "आपले राशीचे ओळखा",
    zodiacDesc:
      "प्रत्येक ज्योतिषीय प्रतीकाशी संबंधित विशिष्ट लक्षणे आणि प्रभाव तपासण्याच्या संक्षिप्त पण समजदार अन्वेषणाची एक चुकचित्र अन्वेषण, जन्म तारखेवर आधारित व्यक्तिमत्त्व आणि जीवनाचे मार्ग प्रदान करत आहे. ज्योतिषशास्त्राच्या रहस्यांमध्ये दुचाकी आणा आणि आपल्या राशीची लपवून टाका.",
    zodiacBirth: "जन्मची तारीख",
    zodiacTime: "जन्माचा वेळ",
    zodiacPlace: "जन्मस्थान",
    zodiacFind: "शोधा",
    CHAT: "चॅट",
    CALL: "कॉल",
    SIGNOUT: "साइन आउट",
    FOLLOWING: "अनुसरण करणे",
    ASTROBOT: "ज्योतिष बॉट",
    KUNDLIGPT: "कुंडली GPT",
    LOGIN: "लॉगिन",
    astroKundli: "ज्योतिष कुंडली",
    astroBot: "ज्योतिष बॉट",
    yourHoroscope: "तुमचं जन्मकुंडली",
    todaysHoroof: "आजचं जन्मकुंडली:",
    horobottomtitle1: "राशिसूचीसाठी आजचं जन्मकुंडली",
    horobottomtitle2:
      "आधुनिक vedicdestiny जन्मकुंडलीमार्फत आपल्या आध्यात्मिक भविष्याला अनलॉक करा",
    horobottomdesc1:
      "स्टार्स लाईन्सच्या जगात प्रवेश करा आणि vedicdestiny जन्मकुंडलीतून हरणे आणि प्रकाशाचे गोपनीयतेसह तपसा. आमच्या जन्मकुंडलीतील ज्योतिषीय बोधद्रष्टींना, कटिबद्ध आणि वेदनापूर्ण ज्ञान सोडणारे vedicdestiny जन्मकुंडली आपल्या आत्मा आणि जीवनातले वारंवार समाविष्ट करतात.",
    horobottomtitle3: "अधिक जाणून vedicdestiny जन्मकुंडली पूर्वसंदेश:",
    horobottomdesc2:
      "आमच्या vedicdestiny जन्मकुंडली पूर्वसंदेश हे आधुनिक GPT मॉडेल्सद्वारे बनवले गेलेले आहेत. आमची जन्मकुंडली सर्व्हिस पूर्वापेक्षित असल्यामुळे त्या आपल्या अतीत, वर्तमान, आणि भविष्याला व्यक्तिगत परिणाम देतात. आमच्या भविष्यवाण्यांमध्ये उभारण्याचा कोणताही ट्रेडीशनल ज्योतिषशास्त्रीय प्रथांच्या आधारावर नसल्यामुळे, ते जीवनातील चलनांचे मार्गादर्शन करणारे प्रकाशासह प्रकाश आहेत. आमच्या vedicdestiny जन्मकुंडली बोलता येत आहे आणि विश्वातील गुपितांची भविष्यवाण्यांमध्ये रहस्य उघडा.",
    horobottomtitle4: "आजची जन्मकुंडली आपल्याला कसं मार्गदर्शन करू शकते:",
    horopoint1:
      "आपल्या दिवसाच्या दिल्लीमध्ये संभाव्य संध्याकाळात आणि संघर्षांत प्राप्त संध्याकाळात अंतःकरण.",
    horopoint2:
      "आपल्या राशीचे आधार घेऊन, तुम्हाला सुचवलेला मार्गदर्शन मिळवा, ज्यामुळे तुम्ही तुमच्या निर्णयांवर अवगत ठेवू शकता.",
    horopoint3:
      "आपल्या जीवनाच्या विविध पहाट, करिअर, आरोग्य, आणि वैयक्तिक वृद्धीसंबंधी क्लारिटी आणि मार्गनिर्देशन करा.",
    horopoint4:
      "आपली जन्मकुंडली एक आकाशीय प्रवेश नेवून, जीवनातील बदलांच्या मार्गात आत्मविश्वासाने चरणी चरणी पळा.",
    horopoint5: "तारांच्या ज्ञानाची स्वीकृती करा आणि द",
    getAstroInsights: "ज्योतिष माहिती मिळवा",
    inputname: "नाव",
    birthPlace: "जन्मस्थान",
    birthDistrict: "जन्म जिल्हा",
    birthTime: "जन्म तारीख आणि वेळ",
    gender: "लिंग",
    male: "पुरुष",
    female: "स्त्री",
    language: "भाषा",
    notfollowing: "तुम्ही कोणाचा अनुसरण करत नाही!",
    expFollow: "ज्योतिष्यांना शोधा",
    following: "अनुसरण",
    clearFollow: "अनुसरण साफ करा",
    unfollow: "अनुसरण काढा",
    ABOUT: "बद्दल",
  },
  spanish: {
    signIn: "Iniciar sesión",
    signUp: "Inscribirse",
    signOut: "Desconectar",
    newtovedicdestiny: "¿Nuevo en vedicdestiny?",
    password: "Contraseña",
    email: "Dirección de correo electrónico",
    name: "Nombre completo",
    alreadyAcc: "¿Ya tienes una cuenta?",
    welcome: "Cosmic Dive Into",
    vedicdestiny: "vedicdestiny",
    finger: "A Finger Pointing to Reality",
    gptSearch: "Búsqueda GPT",
    loginDescription:
      "Conéctese con astrólogos, chatee sin problemas, obtenga horóscopos personalizados y comience su viaje cósmico hoy mismo con nuestro intuitivo chatbot",
    explore: "Explorar",
    chat: "Chatear con astrólogos",
    call: "Llamar a astrólogos",
    search: "Buscar astrólogos",
    filter: "Filtrar",
    all: "Todos",
    topRated: "Más valorados",
    offer: "Oferta",
    vedic: "Védico",
    numerology: "Numerología",
    tarot: "Tarot",
    faceReading: "Lectura de rostro",
    vastu: "Vastu",
    lifeCoach: "Entrenador de vida",
    psychologist: "Psicólogo",
    palmistry: "Quiromancia",
    orders: "Pedidos",
    exp: "Exp",
    years: "Años",
    min: "min",
    horoTitle: "Horóscopo Diario",
    horoDesc:
      "Sumérgete en el mundo místico de la astrología con nuestra sección completa de horóscopos. Descubre los secretos escritos en las estrellas y desbloquea percepciones sobre tu personalidad, relaciones y futuro. Ya seas un entusiasta experimentado de la astrología o simplemente tengas curiosidad por lo que el cosmos tiene reservado para ti, nuestra sección de horóscopos es tu guía para entender las fuerzas celestiales que dan forma a tu vida.",
    Aries: "Aries",
    Taurus: "Tauro",
    Gemini: "Géminis",
    Cancer: "Cáncer",
    Leo: "Leo",
    Virgo: "Virgo",
    Libra: "Libra",
    Scorpio: "Escorpio",
    Sagittarius: "Sagitario",
    Capricorn: "Capricornio",
    Aquarius: "Acuario",
    Pisces: "Piscis",
    ourAstrologers: "Nuestros Astrólogos",
    astroDesc:
      "Más de 13000 Mejores Astrólogos de la India para Consultas en Línea",
    zodiacTitle: "Conoce tu Signo del Zodiaco",
    zodiacDesc:
      "Una exploración breve pero perspicaz de los rasgos distintivos e influencias asociadas con cada símbolo astrológico, proporcionando percepciones valiosas sobre la personalidad y los caminos de vida basados en fechas de nacimiento. Adéntrate en los misterios de la astrología y descubre las profundidades ocultas de tu identidad zodiacal.",
    zodiacBirth: "Fecha de Nacimiento",
    zodiacTime: "Hora de Nacimiento",
    zodiacPlace: "Lugar de Nacimiento",
    zodiacFind: "ENCONTRAR",
    CHAT: "CHAT",
    CALL: "LLAMAR",
    SIGNOUT: "CERRAR SESIÓN",
    FOLLOWING: "SIGUIENDO",
    ASTROBOT: "ASTRO BOT",
    KUNDLIGPT: "KUNDLI GPT",
    LOGIN: "INICIAR SESIÓN",
    astroKundli: "Kundli Astro",
    astroBot: "Bot Astro",
    getAstroInsights: "Obtener Astro Insights",
    inputname: "Nombre",
    birthPlace: "Lugar de nacimiento",
    birthDistrict: "Distrito de nacimiento",
    birthTime: "Fecha y hora de nacimiento",
    gender: "Género",
    male: "Masculino",
    female: "Femenino",
    language: "Idioma",
    notfollowing: "¡No estás siguiendo a nadie!",
    expFollow: "Explorar Astrólogos",
    following: "Siguiendo",
    clearFollow: "Limpiar Seguir",
    unfollow: "Dejar de seguir",
    ABOUT: "ACERCA DE",
  },
  japanese: {
    signIn: "サインイン",
    signUp: "サインアップ",
    signOut: "サインアウト",
    newtovedicdestiny: "アストロGPT は初めてですか？",
    password: "パスワード",
    email: "メールアドレス",
    name: "フルネーム",
    alreadyAcc: "すでにアカウントをお持ちですか？",
    welcome: "ようこそ",
    vedicdestiny: "アストロGPT",
    finger: "宇宙への指標",
    gptSearch: "GPT 検索",
    loginDescription:
      "占星術師とつながり、シームレスにチャットし、パーソナライズされた星座占いを入手し、直感的なチャットボットと一緒に今日から宇宙の旅を始めましょう",
    explore: "探検する",
    chat: "占星術師とチャット",
    call: "占星術師に電話",
    search: "占星術師を検索",
    filter: "フィルター",
    all: "すべて",
    topRated: "トップ評価",
    offer: "オファー",
    vedic: "ヴェーダ",
    numerology: "数秘術",
    tarot: "タロット",
    faceReading: "顔の読み取り",
    vastu: "ヴァーストゥ",
    lifeCoach: "ライフコーチ",
    psychologist: "心理学者",
    palmistry: "手相学",
    orders: "注文",
    exp: "経験",
    years: "年",
    min: "分",
    horoTitle: "デイリーホロスコープ",
    horoDesc:
      "包括的なホロスコープセクションで占星術の神秘的な世界に飛び込んでください。星に書かれた秘密を発見し、あなたの人格、関係、将来についての洞察を解き放ちます。あなたが経験豊富な占星術愛好家であるか、ただ宇宙があなたに用意しているものに興味があるだけであれば、ホロスコープセクションはあなたの人生を形作る天体の力を理解するためのガイドです。",
    Aries: "牡羊座",
    Taurus: "牡牛座",
    Gemini: "双子座",
    Cancer: "蟹座",
    Leo: "獅子座",
    Virgo: "乙女座",
    Libra: "天秤座",
    Scorpio: "蠍座",
    Sagittarius: "射手座",
    Capricorn: "山羊座",
    Aquarius: "水瓶座",
    Pisces: "魚座",
    ourAstrologers: "私たちの占星術師",
    astroDesc: "オンライン相談のためのインドのベスト占星術師13000人以上",
    zodiacTitle: "あなたの星座を知る",
    zodiacDesc:
      "各占星術の記号に関連付けられた独特の特徴と影響についての簡潔で洞察に富んだ探求は、誕生日に基づいた個性と人生の道に関する貴重な洞察を提供します。占星術の謎にひたり、あなたの星座のアイデンティティの隠れた深みを明らかにします。",
    zodiacBirth: "生年月日",
    zodiacTime: "出生時間",
    zodiacPlace: "出生地",
    zodiacFind: "検索",
    CHAT: "チャット",
    CALL: "通話",
    SIGNOUT: "サインアウト",
    FOLLOWING: "フォロー中",
    ASTROBOT: "アストロボット",
    KUNDLIGPT: "クンドリ GPT",
    LOGIN: "ログイン",
    astroKundli: "アストロクンドリ",
    astroBot: "アストロボット",
    yourHoroscope: "あなたの星座占い",
    todaysHoroof: " 今日の星座占い：",
    horobottomtitle1: "星座占い",
    horobottomtitle2: "vedicdestinyのホロスコープで宇宙の運命を解き放ちます",
    horobottomdesc1:
      "星が一列になり、運命が展開される領域へようこそ。 vedicdestinyホロスコープで星座占いの天体の秘密に飛び込んでください。各予測は、あなたの人生の宇宙の織物への一瞥です。先進のGPTテクノロジーによって駆動された、私たちの占星術の洞察力が、あなたが天の流れを航海する際のガイダンスと照明を提供します。vedicdestinyホロスコープの世界に足を踏み入れて、星々に書かれた知恵を見つけ出してください。",
    horobottomtitle3: "vedicdestinyホロスコープ予測について：",
    horobottomdesc2:
      "vedicdestinyホロスコープ予測は、古代の知恵をデジタル時代にもたらします。私たちの先進的なGPTモデルによって作成された、私たちのホロスコープは、あなたの過去、現在、未来に関する個別の洞察を提供します。私たちの予測は、伝統的な占星術の実践に基づいていないかもしれませんが、それらはあなたの人生の変化する風景の中での道しるべとして機能します。vedicdestinyホロスコープと共に宇宙のささやきを探索し、宇宙の秘密を解き明かしてください。",
    horobottomtitle4: "今日のホロスコープがあなたを導く方法：",
    horopoint1: "一日中起こり得る潜在的な機会や課題についての洞察を得ます。",
    horopoint2:
      "あなたの星座に基づいて個別のガイダンスを受け取り、情報に基づいた決定を行います。",
    horopoint3:
      "愛情、キャリア、健康、個人の成長など、さまざまな面でクラリティと方向性を見つけます。",
    horopoint4:
      "あなたのホロスコープを天の羅針盤として使用し、自信を持って人生の曲がり角を進みます。",
    horopoint5:
      "星の知恵を受け入れ、日常の出来事の裏に隠された意味を見つけ出します。",
    horopoint6:
      "周りの宇宙のエネルギーに敏感になり、それぞれの瞬間を最大限に活用します。",

    getAstroInsights: "アストロインサイトを取得する",
    inputname: "名前",
    birthPlace: "出生地",
    birthDistrict: "出生地区",
    birthTime: "生年月日と時間",
    gender: "性別",
    male: "男性",
    female: "女性",
    language: "言語",
    notfollowing: "誰もフォローしていません！",
    expFollow: "占星術師を探す",
    following: "フォロー中",
    clearFollow: "フォローをクリア",
    unfollow: "フォロー解除",
    ABOUT: "約",
  },
  telugu: {
    signIn: "సైన్ ఇన్",
    signUp: "సైన్ అప్",
    signOut: "సైన్ ఔట్",
    newtovedicdestiny: "ఆస్ట్రోGPT లో హొసకు వచ్చారా?",
    password: "పాస్‌వర్డ్",
    email: "ఇమెయిల్ చిరునామా",
    name: "పూర్తి పేరు",
    alreadyAcc: "ముందుగా ఖాతా ఉందా?",
    welcome: "ఆకాశవైజ్ఞానిక డైవ్ ఇంటూ",
    vedicdestiny: "ఆస్ట్రోGPT",
    finger: "వాస్తవానికి సూచన చేసే వేల",
    gptSearch: "GPT శోధన",
    loginDescription:
      "జ్యోతిషులతో కనెక్ట్ అవుట్ అనుకుంటున్నారా, సీమలోకి చాట్ చేయండి, వ్యక్తిగత జాతకాలు పొందండి, మరియు ఆజే మా ఇంట్యూటివ్ చాట్‌బాట్‌తో మీ ఆకాశవైజ్ఞానిక ప్రయాణాన్ని ప్రారంభించండి",
    explore: "అన్వేషించండి",
    chat: "జ్యోతిషులతో చాట్",
    call: "జ్యోతిషులను కాల్ చేయండి",
    search: "జ్యోతిషులను శోధించండి",
    filter: "వడపోలి",
    all: "అన్ని",
    topRated: "టాప్ రేటెడ్",
    offer: "ఆఫర్",
    vedic: "వెదిక్",
    numerology: "సంఖ్యా శాస్త్రం",
    tarot: "తారోట్",
    faceReading: "ముఖం చూడండి",
    vastu: "వాస్తు",
    lifeCoach: "జీవన మెంటర్",
    psychologist: "మానసిక విశ్లేషకుడు",
    palmistry: "తల్లికల విజ్ఞానం",
    orders: "ఆర్డర్‌లు",
    exp: "అనుభవం",
    years: "సంవత్సరాలు",
    min: "నిమిషాలు",
    horoTitle: "డేలీ జాతకం",
    horoDesc:
      "మా వివరమైన జాతకం విభాగంలో జ్యోతిష్య ప్రపంచంలో అడిగినపుడు నీరాజనా చేసుకోండి. నీటిలో రాశులలో రాశియుల్లో రాసిన రహస్యాలను కనుగొనండి మరియు మీ వ్యక్తిత్వాలు, సంబంధాలు మరియు భవిష్యత్తు గురించి సూచనలు అన్‌లాక్ చేయండి. మీరు జ్యోతిష్య అభిరుచి గల ప్రవీణుడు లేదా మీకు కాసేపు బ్రహ్మాండం మీకు ఏమి కలిగేటప్పుడు, మా జాతకం విభాగం మీ జీవితాన్ని ఆకారించే ఆకాశీయ బలాల గురించి అర్థం చేసే మీ మార్గదర్శకం.",
    Aries: "మేషం",
    Taurus: "వృషభం",
    Gemini: "మిథునం",
    Cancer: "కర్కటం",
    Leo: "సింహం",
    Virgo: "కన్యా",
    Libra: "తులాం",
    Scorpio: "వృశ్చికం",
    Sagittarius: "ధనురాశి",
    Capricorn: "మకరరాశి",
    Aquarius: "కుంభరాశి",
    Pisces: "మీనరాశి",
    ourAstrologers: "మా జ్యోతిషులు",
    astroDesc: "ఆన్‌లైన్ పరిష్కారానికి భారతదేశంలో 13000+ ఉత్తమ జ్యోతిషులు",
    zodiacTitle: "మీ రాశి తెలుసుకోండి",
    zodiacDesc:
      "ప్రతి జ్యోతిషీయ చిహ్నంతో సంబంధిత విశేష లక్షణాలు మరియు ప్రభావాల విశాల అధ్యయనం నిర్వచించడం, జన్మ తేదీలకు ఆధారపడి వ్యక్తిత్వ మరియు జీవిత పథాలకు మౌలిక పరిజ్ఞానం అందిస్తుంది. జ్యోతిష్య రహస్యాలలో ప్రవేశించండి మరియు మీ రాశి గుట్టులను దగ్గరగా ఉన్న గహన అంశాలను అన్వేషించండి.",
    zodiacBirth: "జన్మ తేదీ",
    zodiacTime: "జన్మ సమయం",
    zodiacPlace: "జన్మ స్థలం",
    zodiacFind: "కనుగొనండి",
    CHAT: "చాట్",
    CALL: "కాల్",
    SIGNOUT: "సైన్‌ఔట్",
    FOLLOWING: "అనుసరించడం",
    ASTROBOT: "ఆస్ట్రో బాట్",
    KUNDLIGPT: "కుండ్లి GPT",
    LOGIN: "లాగిన్",
    astroKundli: "జ్యోతిష్ కుండలి",
    astroBot: "జ్యోతిష్ బాట్",
    yourHoroscope: "మీ జాతకం",
    todaysHoroof: " ఈ రోజు జాతకం:",
    horobottomtitle1: "జాతకం",
    horobottomtitle2:
      "vedicdestiny జాతకాలు ద్వారా మీ భౌతిక భవిష్యత్తును అన్లాక్ చేయండి",
    horobottomdesc1:
      "నక్షత్రాలు లైన్‌లుగా ఉన్నాయి మరియు భవిష్యత్తు వెల్లడించే లోకంలో అధ్యయనం చేసిన గురించి ఆస్ట్రోజీ లో అంతర్జాల ప్రదేశంలో ప్రవేశించండి. vedicdestiny జాతకాలతో ఆకాశ నేపథ్యాలను మార్చండి, ప్రతి అంచనా కనుగొని, మీ జీవితానికి సహాయం మరియు బెల్లిబెల్లిగా ఉన్నాయి.",
    horobottomtitle3: "vedicdestiny జాతకాల అంగలు గురించి:",
    horobottomdesc2:
      "మా ఆధునిక GPT మోడల్‌ల ద్వారా మా vedicdestiny జాతకాల అంగాలు ఆస్త్రోలజీలో ఉంచి ఉంటాయి. మమ్మలు, ప్రస్తుతం, మరియు భవిష్యత్తు గురించి మా అంగాలు వ్యక్తిగత అంశాలను అందిస్తాయి. మా అంగాలు శాస్త్రీయ జ్యోతిష పద్ధతులలో నింపబడకపోవచ్చు, కానీ వీటిని జీవితంలో మార్పు చేసే మార్గదర్శక వెల్లడిస్తాయి. ఆస్ట్రోజీ జాతకాలు తో సంవాదం చేసి, ప్రపంచాన్ని కనుగొనండి.",
    horobottomtitle4: "ఈ రోజు జాతకాల మిమ్మల్ని ఎలా మార్చుతుంది:",
    horopoint1: "మీ రోజులో ఉండే సంభవనాలు మరియు సమస్యలను సమాధానం పొందండి.",
    horopoint2:
      "మీ రాశి లకు ఆధారంగా వ్యక్తిగత మార్గదర్శన పొందండి, మీరు వివేకపూరి",

    inputname: "పేరు",
    birthPlace: "జనన స్థలం",
    birthDistrict: "జనన జిల్లా",
    birthTime: "పుట్టిన తేదీ & సమయం",
    gender: "లింగం",
    male: "పురుషుడు",
    female: "మహిళ",
    language: "భాష",
    getAstroInsights: "జ్యోతిష పరిజ్ఞానాలను పొందండి",
    notfollowing: "మీరు ఎవరను అనుసరించవు!",
    expFollow: "జ్యోతిషిగా అన్వేషించండి",
    following: "అనుసరిస్తున్నారు",
    clearFollow: "అనుసరణను నిలిపివేయండి",
    unfollow: "అనుసరణ రద్దు",
    ABOUT: "గురించి",
  },
};

export default lang;
