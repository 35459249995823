// 

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addAstroProfile } from "../store/AstroSlice";
import { collection, getDocs } from "firebase/firestore";
import db from '../utils/firebase'; // Your Firestore config

const useAstroProfile = (id)=>{

    const dispatch = useDispatch()

    const fetchData = async () =>{
        try {
            // Firestore logic to fetch data from 'consultants' collection
            const consultantsCollection = collection(db, "consultants");
            const consultantSnapshot = await getDocs(consultantsCollection);
            const consultantsList = consultantSnapshot.docs.map(doc => ({
              id: doc.id, // For reference, optional
              ...doc.data()
            }));
      
            // Dispatch the fetched consultants list to Redux store
            dispatch(addAstroProfile(consultantsList));
          } catch (error) {
            console.error("Error fetching data from Firestore:", error);
          }
    }
    useEffect(()=>{
        fetchData()
    },[])
}
export default useAstroProfile;