import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addChat } from "../store/AstroSlice";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { collection, getDocs } from "firebase/firestore";
import db from '../utils/firebase'; // Your Firestore config

const useChat = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const fetchData = async () =>{
        try {
      // Firestore logic to fetch data from 'consultants' collection
      const consultantsCollection = collection(db, "consultants");
      const consultantSnapshot = await getDocs(consultantsCollection);
      const consultantsList = consultantSnapshot.docs.map(doc => ({
        id: doc.id, // For reference, optional
        ...doc.data()
      }));

      // Dispatch the fetched consultants list to Redux store
      dispatch(addChat(consultantsList));
    } catch (error) {
        console.error("Error fetching data:", error);

        if (error.message === 'Failed to fetch') {

            toast.error("Failed to fetch", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
              });
            navigate("/error")
            
            
        }
    }
    }
    useEffect(()=>{
        fetchData();
    },[])
}
export default useChat;