import React from 'react'

const Coming = () => {
  return (
    <div className='text-xl mb-20 lg:mb-10 lg:text-2xl text-orange-400 mx-20'>
        In the Development Stage..
    </div>
  )
}

export default Coming
